/* eslint-disable */
/* Hi Finand */

module.exports = {
  logout: 'Logout',
  submissionsThisYear: 'Your data requests this year',
  timeline: {
    requestIsMadeHeader: 'The request is made',
    requestIsMadeParagraph:
      'You can make a request for your data using the form below. After receiving the request we will collect the information from different systems.',
    secureMailHeader: 'Encrypted mail is send',
    secureMailParagraph:
      'You will receive an encrypted email to the email address you have provided to us within one month of your request.',
    //expireHeader: 'The link expires',
    expireHeader: 'Opening encrypted email',
    expireParagraph:
      'The email contains instructions on how to open the message.',
    firstDay: 'Day zero',
    toThirtyDays: '1-30 days',
    //sixtyPlusDay: '+60 Days',
    sixtyPlusDay: ' ',
  },
  /*expireHeader: 'Opening encrypted email',
    expireParagraph:
      'The email contains instructions on how to open the message.',*/
  thankYouHeader:
    'Your request has been sent',
  thankYou:
    'You will receive a response from Fortum in 30 days to the email address',
  requestFailed:
    'Sending the request failed. Please try again later or contact customer service.',
  basicDataError:
    "We didn't find any basic data about you in our systems. It is possible that we do not have any data stored about you.",
  headers: {
    electricMessaging: 'Communication settings',
    requestingOwnInformation: 'Request for my data',
    hereIsYourBasicInfo: 'Here is your basic data',
    energyConsumption: 'Energy consumption',
    copyOfYourInfo: 'Copy of your data',
  },
  paragraphs: {
    requestingOwnInformation:
      'We always handle your data with care and your right to maintain, update and delete your data is important to us. You can see the basic data we have collected about you below. If you wish to receive a copy of all the information we have about you in our systems, you can make a request with the form below.',
    requestingOwnInformationHR:
      'We always handle your data with care and your right to maintain, update and delete your data is important to us. If you wish to receive a copy of all the information we have about you in our systems, you can make a request with the form below.',
    hereIsYourBasicInfo:
      'Here is your basic data that we have in our systems. If you our customer currently, you can update your information in the',
    energyConsumption:
      'If you are currently our customer and want to view your consumption history, you can login to My Fortum -service.',
    copyOfYourInfo:
      'If you want to make a request for a copy of your data, you can make it with the form below. In order for us to deliver the data to you, we need your email address where we will send the notification when your request is complete. You can either ask for all you data or just the data required for data portability. We will deliver a package of data for you which is currently stored in our systems. It includes for example your customer data, contract- and product data, electricity consumption data, data concerning you electricity use, Fortum store purchase history and energy efficiency data if it applies to you.',
    copyOfYourInfoAsCSV:
      'Copy of the data will be delivered in CSV-format. The data is best viewed on a spreadsheet program (such as Open Office, Microsoft Excel or Google Docs)',
  },
  labels: {
    name: 'Name',
    address: 'Address',
    customerId: 'Customer number',
    communicationLanguage: 'Communications language',
    phone: 'Telephone',
    accountStatus: 'Customership status',
    marketingPermissions: 'Digital marketing permission',
    priceNotifications: 'Price notifications',
    personalID: 'Social security number',
    email: 'Email',
  },
  missingData: {
    marketingPermissions: 'Not selected',
    priceNotifications: 'Not selected',
  },
  customerStatus: {
    active: 'Active',
    inactive: 'No active contract',
  },
  language: {
    finnish: 'In Finnish',
    swedish: 'In Swedish',
    english: 'In English',
  },
  channels: {
    mobile: 'Phone',
    email: 'Email',
  },
  linkTargets: {
    myFortum: 'https://web.fortum.fi',
    //valpas: 'https://www.fortum.com/valpas',
    valpas: 'https://web.fortum.fi',
    fortumCom: 'https://www.fortum.fi',
    legalNotice: 'https://www.fortum.fi/tietosuoja-ja-rekisterit',
    privacy: 'https://www.fortum.fi/tietosuoja-ja-rekisterit',
  },
  buttons: {
    loginMyFortum: 'Login to Oma Fortum',
    openValpas: 'Login to My Fortum',
    //openValpas: 'Login to Valpas -service',
    requestAllData: 'Request data',
  },
  links: {
    myFortum: 'Oma Fortum -service',
  },
  employee: {
    status: 'State of employment',
    current: 'Current employee',
    former: 'Former employee',
    applicant: 'Job applicant',
  },
  request_type: {
    copy: 'Copy of all my data',
    transfer: 'Data for portability',
  },
  footer: {
    fortumCom: 'Fortum.com',
    privacy: 'Privacy Policy and registers',
    justice: 'Legal notice',
  },
};
