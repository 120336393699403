/* eslint-disable */

module.exports = {
  logout: 'Logg ut',
  submissionsThisYear: 'Dine persondataforespørsler i år',
  timeline: {
    requestIsMadeHeader: 'Forespørsel sendes',
    requestIsMadeParagraph:
      'Du kan sende en forespørsel etter dine  persondata via skjemaet lenger ned på siden. Vi vil da hente inn og samle sammen informasjon lagret i ulike systemer.',
    secureMailHeader: 'Kryptert e-post sendes',
    secureMailParagraph:
      'Du vil få beskjed på e-postadressen du har oppgitt med en lenke for å laste ned dataene.',
    expireHeader: 'Nedlastningslenken løper ut',
    expireParagraph:
      'Av sikkerhetsmessige grunner er dataene bare tilgjengelige i en begrenset periode.',
    firstDay: 'dag 0',
    toThirtyDays: 'maks 30 dager',
    sixtyPlusDay: '30 dager etter utsending av lenke',
  },
  thankYouHeader:
    'Din forespørsel har blitt sendt',
  thankYou:
    'Du vil motta et svar på e-postadressen du har oppgitt innen 30 dager.',
  requestFailed:
    'Innsendingen av forespørselen mislyktes. Vennligst prøv igjen senere eller kontakt kundeservice.',
  basicDataError:
    "Vi kunne ikke finne grunnleggende opplysninger om deg i våre systemer. Det er mulig vi ikke har noen data lagret om deg. ",
  headers: {
    electricMessaging: 'Kommunikasjonsinnstillinger',
    requestingOwnInformation: 'Tilgang til dine persondata',
    hereIsYourBasicInfo: 'Dine viktigste opplysninger',
    energyConsumption: 'Energiforbruk',
    copyOfYourInfo: 'Få kopi av dine data',
  },
  paragraphs: {
    requestingOwnInformation:
      'Vi ivaretar dine data med omhu, og sørger for at du har innsyn i dem og tilgang til å oppdatere eller slette dem. Nedenfor finner du derfor de viktigste opplysningene vi har lagret om deg. Andre data er ikke direkte tilgjengelig via nettet, men hvis du ønsker en kopi av all informasjon om deg i våre systemer kan du sende en forespørsel ved hjelp av skjemaet lenger ned på siden.',
    requestingOwnInformationHR:
      'Vi behandler alltid dine data med forsiktighet, og din rett til å opprettholde, oppdatere og slette data er viktig for oss. Hvis du ønsker å motta en kopi av all informasjonen vi har om deg i vårt system, så kan du sende en forespørsel ved å bruke skjemaet nedenfor.',
    hereIsYourBasicInfo:
      'Her er de viktigste opplysningene om deg som er lagret i våre systemer. Hvis du er en nåværende kunde kan du oppdatere informasjonen på',
    energyConsumption:
      'Hvis du er en nåværende kunde og vil se din forbrukshistorikk kan du logge inn på Min Side.',
    copyOfYourInfo:
      'Hvis du ønsker en kopi av alle dataene dine kan du sende en forespørsel med dette skjemaet. For å sende deg informasjonen trenger vi en e-postadresse, der du vil få beskjed når dataene er klare. E-postadressen brukes kun til å levere informasjonen og vil ikke bli lagret etter at forespørselen er fullført.',
    copyOfYourInfoAsCSV:
      'En kopi av dine data vil bli levert i XLSX (Excel) og DOCX (Word) format.',
  },
  labels: {
    name: 'Navn',
    address: 'Faktureringsadresse',
    customerId: 'Kundenummer',
    communicationLanguage: 'Språkvalg',
    phone: 'Telefon',
    accountStatus: 'Kundestatus',
    marketingPermissions: 'Innstillinger for elektronisk markedsføring',
    priceNotifications: 'Prisoppdateringer',
    personalID: 'Personnummer',
    email: 'E-post',
  },
  missingData: {
    marketingPermissions: 'Ikke valgt',
    priceNotifications: 'Ikke valgt',
  },
  customerStatus: {
    active: 'Ikke valgt',
    inactive: 'Ikke valgt',
  },
  language: {
    norsk: 'Norsk',
    english: 'In English',
  },
  channels: {
    mobile: 'Telefon',
    email: 'E-post',
  },
  linkTargets: {
    myFortum: 'https://minside.fortum.no/Users/Account/AccessDenied?ReturnUrl=%2F',
    valpas: 'https://minside.fortum.no/Users/Account/AccessDenied?ReturnUrl=%2F',
    fortumCom: 'https://www.fortum.no',
    legalNotice: 'https://www.fortum.no/personvern',
    privacy: 'https://www.fortum.no/personvern',
  },
  buttons: {
    loginMyFortum: 'Min Side',
    openValpas: 'Min Side',
    requestAllData: 'Send forespørsel',
  },
  links: {
    myFortum: 'Min Side',
  },
  employee: {
    status: 'Ansettelsesforhold',
    current: 'Nåværende ansatt',
    former: 'Tidligere ansatt',
    applicant: 'Jobbsøker',
  },
  footer: {
    fortumCom: 'Fortum.no',
    privacy: 'Personvern',
    justice: 'Bruksvilkår',
  },
};
