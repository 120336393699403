import React from 'react';
import axios from 'axios';
import {
  Grid, Row, Col, InputField, Button, Heading, ContentText, Loader,
} from '@fortum/elemental-ui';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

class BasicData extends React.Component {
  constructor(props) {
    super(props);
    const country = window.localStorage.getItem('country');

    this.state = {
      country,
      name: '',
      address: '',
      customerId: '',
      preferredLanguge: '',
      phoneNumber: '',
      contractStatus: '',
      loading: country.toUpperCase() !== 'SE',
      error: false,
    };
  }

  componentWillMount() {
    const token = window.sessionStorage.getItem('access_token');
    const idToken = window.sessionStorage.getItem('id_token');
    const country = window.localStorage.getItem('country');
    if (country.toUpperCase() !== 'SE') {
      axios({
        method: 'GET',
        url: `/api/data/basic/${country}`,
        headers: {
          Authorization: `${token}`,
          idToken,
        },
      })
        .then((res) => {
          const { data } = res;
          const ACTIVE_TEXT = this.props.intl.formatMessage({ id: 'customerStatus.active' });
          const INACTIVE_TEXT = this.props.intl.formatMessage({ id: 'customerStatus.inactive' });

          /* let marketingPermissions = this.props.intl.formatMessage({
            id: 'missingData.marketingPermissions',
          });
          let priceNotifications = this.props.intl.formatMessage({
            id: 'missingData.priceNotifications',
          });

          if (data.marketingPermissionsChannels && data.marketingPermissionsChannels.length > 0) {
            marketingPermissions =
            data.marketingPermissionsChannels.map(permission => this.props.intl.formatMessage({
              id: `channels.${permission.toLowerCase()}`
            }));
            marketingPermissions = marketingPermissions.join(', ');
          }

          if (data.priceNotificationsChannels && data.priceNotificationsChannels.length > 0) {
            priceNotifications =
            data.priceNotificationsChannels.map(permission => this.props.intl.formatMessage({
              id: `channels.${permission.toLowerCase()}`
            }));
            priceNotifications = priceNotifications.join(', ');
          }
*/
          this.setState({
            name: `${data.firstName || ''} ${data.lastName || ''}`,
            address: data.address,
            customerId: data.customerId,
            preferredLanguge: data.language ? this.props.intl.formatMessage({
              id: `language.${data.language.toLowerCase()}`,
            }) : '',
            phoneNumber: data.mobileNumber,
            contractStatus: data.activeCustomer ? ACTIVE_TEXT : INACTIVE_TEXT,
            loading: false,
          });

          window.localStorage.setItem('customer_id', data.customerId);
        })
        .catch(() => this.setState({ loading: false, error: true }));
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="wrapper">
          {this.props.intl.locale !== 'se' && (
            <Heading level={2}>
              <FormattedMessage id="headers.hereIsYourBasicInfo" />
            </Heading>
          )}
          <Loader size={128} />
        </div>
      );
    }

    return (
      <div className="wrapper">
        {this.state.country !== 'se' && (
          <Heading level={2}>
            <FormattedMessage id="headers.hereIsYourBasicInfo" />
          </Heading>
        )}
        {!this.state.error
          && (
          <ContentText>
            <FormattedMessage id="paragraphs.hereIsYourBasicInfo" />
            &nbsp;
            {this.state.country !== 'se'
              && (
              <a href={this.props.intl.formatMessage({ id: 'linkTargets.myFortum' })} rel="noopener noreferrer" target="_blank">
                <FormattedMessage id="links.myFortum" />
              </a>
              )
            }
.
          </ContentText>
          )
        }
        {this.state.error && (
          <ContentText>{this.props.intl.formatMessage({ id: 'basicDataError' })}</ContentText>
        )}
        {this.state.country === 'se' && (
          <Button
            href={this.props.intl.formatMessage({ id: 'linkTargets.myFortum' })}
            tag="a"
            target="_blank"
            status="secondary"
            className="margin-top"
          >
            {this.props.intl.formatMessage({ id: 'buttons.loginMyFortum' })}
          </Button>
        )}
        {this.state.country !== 'se'
          && !this.state.error && (
            <div>
              <Grid className="small-wrapper text-left margin-top-large">
                <Row>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="name"
                      label={this.props.intl.formatMessage({ id: 'labels.name' })}
                      value={this.state.name}
                    />
                  </Col>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="postalAddress"
                      label={this.props.intl.formatMessage({ id: 'labels.address' })}
                      value={this.state.address}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="customerId"
                      label={this.props.intl.formatMessage({ id: 'labels.customerId' })}
                      value={this.state.customerId ? this.state.customerId.toString() : ''}
                    />
                  </Col>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="preferredLanguge"
                      label={this.props.intl.formatMessage({ id: 'labels.communicationLanguage' })}
                      value={this.state.preferredLanguge}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="phoneNumber"
                      label={this.props.intl.formatMessage({ id: 'labels.phone' })}
                      value={this.state.phoneNumber}
                    />
                  </Col>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="customerStatus"
                      label={this.props.intl.formatMessage({ id: 'labels.accountStatus' })}
                      value={this.state.contractStatus}
                    />
                  </Col>
                </Row>
              </Grid>
              {/* <Grid className="small-wrapper text-center margin-top-large">
                <Heading level={3} className="margin-bottom-none">
                  {this.props.intl.formatMessage({ id: 'headers.electricMessaging' })}
                </Heading>
                <Row className="text-left">
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="marketingPermissions"
                      label={this.props.intl.formatMessage({ id: 'labels.marketingPermissions' })}
                      value={this.state.marketingPermissions}
                    />
                  </Col>
                  <Col xxs={12} m={6}>
                    <InputField
                      disabled
                      onChange={() => true}
                      className="full-width margin-top"
                      name="priceNotifications"
                      label={this.props.intl.formatMessage({ id: 'labels.priceNotifications' })}
                      value={this.state.priceNotifications}
                    />
                  </Col>
                </Row>
              </Grid> */}
              <Button
                href={this.props.intl.formatMessage({ id: 'linkTargets.myFortum' })}
                tag="a"
                target="_blank"
                status="secondary"
                className="margin-top-large"
              >
                {this.props.intl.formatMessage({ id: 'buttons.loginMyFortum' })}
              </Button>

              <hr className="margin-top-large" />

              <Heading className="margin-top-small" level={3}>
                {this.props.intl.formatMessage({ id: 'headers.energyConsumption' })}
              </Heading>
              <ContentText>{this.props.intl.formatMessage({ id: 'paragraphs.energyConsumption' })}</ContentText>
              <Button
                href={this.props.intl.formatMessage({ id: 'linkTargets.valpas' })}
                tag="a"
                target="_blank"
                status="secondary"
                className="margin-top"
              >
                {this.props.intl.formatMessage({ id: 'buttons.openValpas' })}
              </Button>
            </div>
        )}
      </div>
    );
  }
}

BasicData.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(BasicData);
