/* eslint-disable */

module.exports = {
  mainHeader: 'Zgłoszenie dotyczące danych osobowych',
  // mainParagraph: 'Przepraszamy, formularz zgłoszeniowy jest w tej chwili niedostępny. Pracujemy nad tym, żeby przywrócić jego poprawne funkcjonowanie. Skontaktuj się z nami za pomocą formularza kontaktowego do Zespołu ds. {termLink}',
  // mainParagraphlink: 'Prywatności Contact - Privacy Office PL | Fortum.pl',
  mainParagraph: 'Wypełniając poniższy formularz możesz zgłosić chęć skorzystania z praw osoby, której dotyczą dane na mocy tzw. RODO, czyli Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku. Fortum odpowie na każe zgłoszenie przesłane poprzez poniższy formularz bez zbędnej zwłoki, nie później niż w terminie miesiąca od daty zgłoszenia.',
  formHeader: 'Formularz zgłoszenia',
  stepOneHeader: 'Dane osobowe',
  stepTwoHeader: 'Powiązanie z Fortum',
  stepThreeHeader: 'Temat zgłoszenia',
  requestFailed: 'Wystąpił błąd przy przesyłaniu zgłoszenia. Proszę spróbować ponownie później lub skontaktować się z obsługą klienta.',
  thankYou: 'Zgłoszenie zostało przesłane',
  thankYouParagraph: 'W ciągu 30 dni otrzymasz odpowiedź od Fortum.',
  attention: 'UWAGA:',
  attentionText: 'Zgłoszenie zostanie przeprocesowane w momencie potwierdzenia Państwa tożsamości. W przypadku wątpliwości będziemy się z Państwem kontaktować.',
  labels: {
    first_name: 'Imię',
    last_name: 'Nazwisko',
    ssn: 'PESEL',
    ssn_code: '11 cyfr',
    customer_id: 'Nr Klienta',
    customer_id_code: 'Numer Klienta Fortum',
    email: 'adres e-mail',
    contact_number: 'numer kontaktowy',
    address_string: 'ulica i nr domu/mieszkania',
    zip_code: 'kod pocztowy',
    city: 'miasto',
    are_you_customer: 'Jestem / byłem Klientem Fortum',
    current_customer: 'aktywny Klient',
    ex_customer: 'były Klient',
    contract_type: 'umowa na:',
    electricity: 'energię elektryczną',
    gas: 'gaz',
    heating: 'ciepło',
    are_professional: 'Inne powiązanie z Fortum',
    company: 'Spółka Fortum',
    professional_current_employee: 'aktywny Pracownik',
    professional_ex_employee: 'były Pracownik ',
    professional_potential_employee: 'Kandydat na Pracownika',
    professional_partner_vendor: 'Partner / Dostawca ',
    professional_other: 'inne, proszę podać',
    request_type: 'Zgłoszenie dotyczy',
    get_my_data: 'prawo dostępu do danych',
    erase_personal_data: 'prawo do bycia zapomnianym',
    restrict_processing: 'prawo do ograniczenia przetwarzania danych',
    object_processing: 'prawo do sprzeciwu ',
    correct_data: 'prawo do sprostowania danych',
    transfer_data: 'prawo do przenoszenia danych ',
    stop_marketing_data: 'wycofanie zgody marketingowej',
    contact_channel: 'Preferowana metoda kontaktu',
    contact_email: 'e-mail',
    contact_phone: 'telefon',
    select_option: 'proszę wybrać',
    termsAndConditions: 'Rozumiem, że moje dane osobowe będą przetwarzane zgodnie z {termLink} i oświadczam, że' +
      ' zapoznałem się z jej treścią.',
    termsAndConditionsLink: 'Polityką Prywatności Fortum'
  },
  errors: {
    required: 'proszę uzupełnić pole obowiązkowe',
    ssn: 'proszę podać poprawny numer PESEL',
    email: 'proszę podać poprawny adres e-mail',
  },
  buttons: {
    send: 'wyślij zgłoszenie',
  },
  linkTargets: {
    fortumCom: 'https://www.fortum.pl',
  },
  footer: {
    fortumCom: 'Fortum.pl',
  },
};
