/* eslint-disable max-len */
import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  Grid, Row, Col, InputField, Button, ContentText, Heading, Radio, Loader,
} from '@fortum/elemental-ui';

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

class RequestAllData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      sent: false,
      employment_status: undefined,
      loading: false,
      submitError: false,
      request_type: 'Copy of my data',
      country: window.localStorage.getItem('country'),
    };

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm() {
    const token = window.sessionStorage.getItem('access_token');
    this.setState({ loading: true });
    axios
      .post(
        '/api/request/copy-of-all',
        {
          email: this.state.email,
          request_type: this.state.request_type,
          country: window.localStorage.getItem('country'),
          customer_language: this.props.intl.locale,
          role: this.state.employment_status || 'Private Customer',
          channel_type: this.props.userType !== 'employee' ? 'B2C' : 'HR',
          customer_id: window.localStorage.getItem('customer_id') || undefined,
          given_name: window.sessionStorage.getItem('firstName'),
          family_name: window.sessionStorage.getItem('lastName'),
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => this.setState({ sent: true, loading: false, submitError: false }))
      .catch(() => this.setState({ sent: false, loading: false, submitError: true }));
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          padding: '0 0 2em 0',
        }}
      >
        <div className="wrapper">
          <Heading level={2}>
            {this.props.intl.formatMessage({ id: 'headers.copyOfYourInfo' })}
          </Heading>
          {this.state.country !== 'se'
            && (
            <img
              style={{
                width: '100%',
                padding: '0 0.5em',
                maxWidth: '460px',
              }}
              src="/File_Example.png"
              alt="Example of file delivered"
            />
            )
          }
          {this.state.country === 'se'
            && (
            <img
              style={{
                width: '100%',
                padding: '0 0.5em',
                maxWidth: '460px',
              }}
              src="/PDF_Example.png"
              alt="Example of file delivered"
            />
            )
          }
          <ContentText lineHeight="loose" className="margin-top">
            {this.props.intl.formatMessage({ id: 'paragraphs.copyOfYourInfo' })}
          </ContentText>
          <Grid
            className="text-left"
            style={{
              maxWidth: '25em',
              width: '100%',
              margin: 'auto',
              padding: '0 1em',
              display: this.state.sent ? 'none' : 'block',
            }}
          >
            <Row>
              <Col xxs={12}>
                <InputField
                  disabled
                  key="input.name"
                  onChange={() => true}
                  className="full-width margin-top"
                  name="name"
                  label={this.props.intl.formatMessage({ id: 'labels.name' })}
                  value={`${this.props.user.firstName || ''} ${this.props.user.lastName || ''}`}
                />
              </Col>
            </Row>
            <Row>
              <Col xxs={12}>
                <InputField
                  disabled
                  onChange={() => true}
                  className="full-width margin-top"
                  name="ssn"
                  label={this.props.intl.formatMessage({ id: 'labels.personalID' })}
                  value={this.props.user.personalId || ''}
                />
              </Col>
            </Row>
            <Row>
              <Col xxs={12}>
                <InputField
                  required
                  className="full-width margin-top"
                  name="email"
                  label={this.props.intl.formatMessage({ id: 'labels.email' })}
                  value={this.state.email}
                  onChange={e => this.setState({ [e.target.name]: e.target.value })}
                />
              </Col>
            </Row>
            {this.props.userType === 'employee' && (
              <Row className="margin-top text-left">
                <Col xxs={12}>
                  <div style={{ marginBottom: '1rem' }}>
                    <ContentText>
                      {this.props.intl.formatMessage({ id: 'employee.status' })}
*
                    </ContentText>
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <Radio
                      required
                      checked={this.state.employment_status === 'Former Employee'}
                      label={this.props.intl.formatMessage({ id: 'employee.former' })}
                      name="employment_status"
                      onChange={e => this.setState({ [e.target.name]: e.target.value })}
                      value="Former Employee"
                    />
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <Radio
                      required
                      checked={this.state.employment_status === 'Employee'}
                      label={this.props.intl.formatMessage({ id: 'employee.current' })}
                      name="employment_status"
                      onChange={e => this.setState({ [e.target.name]: e.target.value })}
                      value="Employee"
                    />
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <Radio
                      required
                      checked={this.state.employment_status === 'Job Applicant'}
                      label={this.props.intl.formatMessage({ id: 'employee.applicant' })}
                      name="employment_status"
                      onChange={e => this.setState({ [e.target.name]: e.target.value })}
                      value="Job Applicant"
                    />
                  </div>
                </Col>
              </Row>
            )}
            {this.state.country === 'fi' && (
              <Row className="margin-top text-left">
                <Col xxs={12}>
                  <div style={{ marginBottom: '1rem' }}>
                    <Radio
                      required
                      key="request_type.copy"
                      checked={this.state.request_type === 'Copy of my data'}
                      label={this.props.intl.formatMessage({ id: 'request_type.copy' })}
                      name="request_type"
                      onChange={e => this.setState({ [e.target.name]: e.target.value })}
                      value="Copy of my data"
                    />
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <Radio
                      required
                      key="request_type.transfer"
                      checked={this.state.request_type === 'Transfer my data'}
                      label={this.props.intl.formatMessage({ id: 'request_type.transfer' })}
                      name="request_type"
                      onChange={e => this.setState({ [e.target.name]: e.target.value })}
                      value="Transfer my data"
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col xxs={12}>
                {!this.state.loading
                  && (
                  <Button
                    key="buttons.requestAllData"
                    disabled={!validateEmail(this.state.email) || this.state.loading}
                    onClick={this.submitForm}
                    className="margin-top full-width text-center"
                  >
                    {this.props.intl.formatMessage({ id: 'buttons.requestAllData' })}
                  </Button>
                  )
                }
                {this.state.loading
                  && (
                  <div className="margin-top text-center">
                    <Loader size={48} />
                  </div>
                  )
                }
                {this.state.submitError && !this.state.loading
                  && (
                  <ContentText
                    className="margin-top text-center"
                    style={{
                      color: '#eb6437',
                    }}
                  >
                    {this.props.intl.formatMessage({ id: 'requestFailed' })}
                  </ContentText>
                  )
                }
              </Col>
            </Row>
          </Grid>
          {this.state.sent && !this.state.submitError && (
            <div>
              <Heading level={3}>
                {this.props.intl.formatMessage({ id: 'thankYouHeader' })}
              </Heading>
              <ContentText>
                {this.props.intl.formatMessage({ id: 'thankYou' })}
                {' '}
                {this.state.email}
              </ContentText>

              <Link to={`/${this.state.country}/logout`}>
                <Button status="secondary" className="margin-top">
                  {this.props.intl.formatMessage({ id: 'logout' })}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RequestAllData.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    personalId: PropTypes.string,
  }),
  userType: PropTypes.string,
};

export default injectIntl(RequestAllData);
