const randomString = (length) => {
  const crypto = window.crypto
    || window.msCrypto || {
    getRandomValues: (array) => {
      const arr = [];
      for (let i = 0, l = array.length; i < l; i += 1) {
        arr[i] = Math.floor(Math.random() * 256);
      }
      return arr;
    },
  };

  if (crypto.getRandomValues === undefined) {
    throw new Error('crypto is not supported on this browser');
  }

  const bytes = new Uint8Array(length);
  const random = crypto.getRandomValues(bytes);
  const result = [];
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  random.forEach((c) => {
    result.push(charset[c % charset.length]);
  });
  return result.join('');
};

module.exports = randomString;
