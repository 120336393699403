import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid, IconCustomer, IconCross, IconChevronDown, colors, ContentText,
} from '@fortum/elemental-ui';
import { injectIntl, intlShape } from 'react-intl';
import Logo from './logo';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      panelOpen: false,
    };

    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel() {
    this.setState(prevState => ({ panelOpen: !prevState.panelOpen }));
  }

  render() {
    const path = window.location.pathname;
    return (
      <div
        style={{
          background: '#5AC37D',
        }}
      >
        <header className="wrapper padding-top-small padding-bottom-small cf white-text">
          <Grid
            style={{
              position: 'relative',
            }}
          >
            <div
              style={{
                marginTop: '0.1em',
              }}
              className="left"
            >
              <Logo />
            </div>
            {this.props.country !== 'pl'
              && (
              <div
                tabIndex="0"
                role="button"
                className="right open-panel"
                onKeyUp={this.togglePanel}
                onClick={this.togglePanel}
              >
                <IconCustomer
                  style={{
                    display: 'block',
                    float: 'left',
                    marginRight: '1em',
                  }}
                  name="customer"
                  size={36}
                  color={colors.snowWhite}
                />
                <div style={{ float: 'left' }}>
                  <ContentText
                    style={{
                      display: 'block',
                      float: 'left',
                      marginTop: '0.5em',
                      marginBottom: '0',
                      color: colors.snowWhite,
                    }}
                  >
                    {this.props.userName || '...'}
                  </ContentText>
                </div>
                {this.state.panelOpen && (
                <IconCross
                  style={{
                    display: 'block',
                    float: 'left',
                    marginTop: '0.2em',
                  }}
                  name="cross"
                  size={32}
                  color={colors.snowWhite}
                />
                )}
                {!this.state.panelOpen && (
                <IconChevronDown
                  style={{
                    display: 'block',
                    float: 'left',
                    marginTop: '0.1em',
                  }}
                  name="chevron_down"
                  size={32}
                  color={colors.snowWhite}
                />
                )}
              </div>
              )
            }

            {this.props.country !== 'pl'
              && (
              <div
                className={`user-panel animated ${
                  this.state.panelOpen ? 'fadeInDown' : 'fadeOutDown'
                }`}
              >
                <ContentText
                  style={{
                    color: colors.snowWhite,
                  }}
                >
                  <Link to={`/${this.props.country}/logout`}>{this.props.intl.formatMessage({ id: 'logout' })}</Link>
                </ContentText>
                {this.props.country === 'fi'
                && <hr />
                }
                {this.props.country === 'fi'
                && (
                <ContentText
                  style={{
                    marginBottom: '0',
                    color: colors.snowWhite,
                  }}
                >
                  <a
                    className={this.props.intl.locale === 'fi' ? 'active-lang' : ''}
                    href={`${path}?lang=fi`}
                  >
                    FI
                  </a>
&nbsp; | &nbsp;
                  <a
                    className={this.props.intl.locale === 'sv' ? 'active-lang' : ''}
                    href={`${path}?lang=sv`}
                  >
                    SV
                  </a>
&nbsp; | &nbsp;
                  <a
                    className={this.props.intl.locale === 'en' ? 'active-lang' : ''}
                    href={`${path}?lang=en`}
                  >
                    EN
                  </a>
                </ContentText>
                )
                }
              </div>
              )
            }
          </Grid>
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  intl: intlShape.isRequired,
  country: PropTypes.string,
  userName: PropTypes.string,
};

export default injectIntl(Header);
