/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  Row, Col, ContentText, Button, Loader,
} from '@fortum/elemental-ui';

const SendTheForm = ({
  loading,
  intl,
  submitError,
}) => (
  <fieldset>
    <Row
      style={{
        marginTop: '-0.5rem',
      }}
    >
      <Col xxs={12}>
        {loading
        && (
        <div
          className="text-center"
          style={{
            width: '100%',
            maxWidth: '22.5rem',
          }}
        >
          <Loader size={48} />
        </div>
        )
        }
        {!loading
        && (
        <Button
          type="submit"
          className="text-center"
          style={{
            width: '100%',
            maxWidth: '22.5rem',
          }}
        >
          {intl.formatMessage({ id: 'buttons.send' })}
        </Button>
        )
        }
        {submitError && !loading
        && (
        <ContentText
          className="margin-top"
          style={{
            color: '#eb6437',
          }}
        >
          {intl.formatMessage({ id: 'requestFailed' })}
        </ContentText>
        )
        }
      </Col>
    </Row>
  </fieldset>
);

SendTheForm.propTypes = {
  intl: intlShape.isRequired,
  submitError: PropTypes.bool,
  loading: PropTypes.bool,
};

export default injectIntl(SendTheForm);
