import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Loader } from '@fortum/elemental-ui';
import { Route, Switch } from 'react-router-dom';
import crypto from 'crypto';
import { Toaster, toast } from 'react-hot-toast';
import Header from './components/Header';
import Footer from './components/Footer';
import PageGDPR from './pages/gdpr';
import PageGDPRPoland from './pages/gdpr-poland';
import Logout from './components/Logout';
import NotFound from './pages/error-pages/404';
import randomString from './utils/randomString';
import Login from './components/Login/login';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstName: '',
        lastName: '',
      },
      validating: true,
    };
  }

  componentWillMount() {
    if (this.props.country.toUpperCase() === 'PL') {
      return this.setState({
        validating: false,
      });
    }

    if (window.sessionStorage.getItem('access_token')) {
      axios
        .get('/api/auth/user-info', {
          headers: {
            Authorization: window.sessionStorage.getItem('access_token'),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              user: {
                firstName: window.sessionStorage.getItem('firstName'),
                lastName: window.sessionStorage.getItem('lastName'),
                personalId: window.sessionStorage.getItem('personalId'),
              },
              validating: false,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            toast.error('Invalid session. Redirecting for login');
            setTimeout(() => {
              this.fgLogin();
            }, 1000);
          } else {
            toast.error(
              'Oops! Something went wrong at our end. Please try again later',
            );
          }
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
    const country = window.localStorage.getItem('country');
    if (
      !country
      || country.length < 2
      || !['fi', 'se', 'no', 'pl'].includes(country)
    ) {
      setTimeout(() => {
        toast.error('Invalid URL');
      }, 1000);
    }
    if (authorizationCode) {
      if (!window.sessionStorage.getItem('access_token')) {
        this.setFgToken(authorizationCode);
      }
    } else if (
      !window.sessionStorage.getItem('access_token')
      && ['fi', 'se', 'no'].includes(country)
    ) {
      this.fgLogin();
    }
  }

  fgLogin() {
    window.sessionStorage.clear();
    axios
      .get(`/api/auth/config-info/${window.localStorage.getItem('country')}`)
      .then((res) => {
        const config = res.data;
        const clientId = config.clientId;
        const authorizationEndpoint = config.frEndpoint;
        const redirectUri = config.redirectUri;
        const state = randomString(16);
        const nonce = randomString(16);
        const scope = 'openid profile';
        const response_type = 'code';

        const initiateLogin = async () => {
          function base64URLEncode(str) {
            return str
              .toString('base64')
              .replace(/\+/g, '-')
              .replace(/\//g, '_')
              .replace(/=/g, '');
          }
          const codeVerifier = base64URLEncode(crypto.randomBytes(64));
          const codeChallenge = base64URLEncode(
            crypto
              .createHash('sha256')
              .update(codeVerifier)
              .digest(),
          );

          window.localStorage.setItem('codeVerifier', codeVerifier);

          const authorizationUrl = `${authorizationEndpoint}?scope=${scope}&response_type=${response_type}&client_id=${clientId}&locale=${
            this.props.country
          }&redirect_uri=${encodeURIComponent(
            redirectUri,
          )}&state=${state}&nonce=${nonce}&code_challenge=${codeChallenge}&code_challenge_method=S256&acr_values=${
            this.props.country
          }passthroughauthentication`;

          window.location.href = authorizationUrl;
        };

        initiateLogin();
      })
      .catch((err) => {
        console.log('error while fetching data from API', err);
        toast.error('Oops! Something went wrong. Please try again');
      });
  }

  setFgToken() {
    const exchangeCodeForTokens = async (authorizationCode) => {
      let tokenEndpoint; let
        body;
      await axios
        .get(`/api/auth/config-info/${window.localStorage.getItem('country')}`)
        .then((res) => {
          const config = res.data;
          const codeVerifier = window.localStorage.getItem('codeVerifier');
          const clientId = config.clientId;
          tokenEndpoint = config.tokenEndpoint;
          const redirectUri = config.redirectUri;

          body = new URLSearchParams({
            grant_type: 'authorization_code',
            code: authorizationCode,
            redirect_uri: redirectUri,
            client_id: clientId,
            code_verifier: codeVerifier,
          });
        })
        .catch((err) => {
          console.log('error exchanging token', err);
          toast.error('Oops! Something went wrong. Please try again');
        });

      const decryptTokenPayload = async (token) => {
        const tokenParts = token.split('.');
        if (tokenParts.length !== 3) {
          throw new Error('Invalid token format');
        }
        const payloadBase64 = tokenParts[1];
        const payload = JSON.parse(atob(payloadBase64));
        return payload;
      };

      try {
        await axios
          .post(tokenEndpoint, body, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
          .then(async (res) => {
            const { access_token, id_token, refresh_token } = res.data;
            const decryptedIdToken = await decryptTokenPayload(
              id_token,
              'fortum123',
            );
            window.sessionStorage.setItem(
              'firstName',
              decryptedIdToken.givenName,
            );
            window.sessionStorage.setItem(
              'lastName',
              decryptedIdToken.familyName,
            );
            window.sessionStorage.setItem('personalId', decryptedIdToken.ssn);
            // Store tokens securely
            window.sessionStorage.setItem('access_token', access_token);
            window.sessionStorage.setItem('id_token', id_token);
            window.sessionStorage.setItem('refresh_token', refresh_token);
            const country = window.localStorage.getItem('country');
            const userType = window.localStorage.getItem('userType');
            window.location.href = `/${country}${
              userType && userType === 'employee' ? `/${userType}` : ''
            }#success`;
          });
      } catch (error) {
        console.error('Error exchanging code for tokens:', error);
        toast.error('Oops! Something went wrong. Please try again');
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');

    exchangeCodeForTokens(authorizationCode);
  }

  render() {
    return (
      <div>
        <div>
          <Toaster position="top-center" toastOptions={{ duration: 5000 }} />
        </div>
        <Header
          country={this.props.country}
          userName={this.state.user.firstName}
        />

        <main>
          {!this.state.validating && (
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/:country(fi|no|se)/logout"
                component={Logout}
              />
              <Route
                exact
                path="/:country(fi|no|se)"
                render={props => (
                  <PageGDPR match={props.match} user={this.state.user} />
                )}
              />
              <Route
                exact
                path="/pl"
                render={props => (
                  <PageGDPRPoland match={props.match} user={this.state.user} />
                )}
              />
              <Route
                exact
                path="/:country(fi|se|no)/:userType(employee)"
                render={props => (
                  <PageGDPR match={props.match} user={this.state.user} />
                )}
              />
              <Route component={NotFound} />
            </Switch>
          )}
        </main>

        {this.state.validating && (
          <div className="wrapper margin-top-large text-center margin-bottom-large">
            <Loader size={256} />
          </div>
        )}
        <Footer country={this.props.country} />
      </div>
    );
  }
}

App.propTypes = {
  country: PropTypes.string,
};

export default App;
