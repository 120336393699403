/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Heading, Grid } from '@fortum/elemental-ui';
import RequestAllDataPoland from '../components/RequestAllDataPoland';

const PageGDPR = ({ match, user, intl }) => (
  <div className="text-center">
    <DocumentTitle title={`${intl.formatMessage({
      id: 'mainHeader',
    })} – Fortum`}
    />
    <Grid>
      <div className="text-center margin-top">
        <Heading level={1}>
          <FormattedMessage defaultMessage="Missing" id="mainHeader" />
        </Heading>
        <p style={{
          fontFamily: 'GT-Eesti-Pro-Display,arial,sans-serif',
          fontWeight: '400',
          lineHeight: '1.618',
          fontSize: '1.125rem',
          fontStyle: 'normal',
        }}
        >
          <FormattedMessage defaultMessage="Missing" id="mainParagraph" />
        </p>
      </div>
    </Grid>
    <hr className="thick" />
    <Grid className="no-max-width-p max-width-50">
      <RequestAllDataPoland user={user} userType={match.params.userType} />
    </Grid>
  </div>
);

PageGDPR.propTypes = {
  intl: intlShape,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    personalId: PropTypes.string,
  }),
};

export default injectIntl(PageGDPR);
