import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import {
  IconEnergyMarketData,
  IconImagesAndLogos,
  IconHazardousWasteAndDetoxification,
  colors,
  ContentText,
} from '@fortum/elemental-ui';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const ProgressMap = ({ intl }) => (
  <div
    style={{
      width: '100%',
      background: '#F7F7F7',
    }}
  >
    <VerticalTimeline className="marginless text-left">
      <VerticalTimelineElement
        className="vertical-timeline-element--laptop"
        iconStyle={{ background: '#79a0d4' }}
        icon={<IconEnergyMarketData color={colors.snowWhite} />}
      >
        <ContentText className="vertical-timeline-element-title">
          {intl.formatMessage({ id: 'timeline.requestIsMadeHeader' })}
          <span className="timeline-date">{intl.formatMessage({ id: 'timeline.firstDay' })}</span>
        </ContentText>
        <ContentText className="vertical-timeline-element-text">
          {intl.formatMessage({ id: 'timeline.requestIsMadeParagraph' })}
        </ContentText>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--envelope"
        iconStyle={{ background: '#79a0d4' }}
        icon={<IconImagesAndLogos color={colors.snowWhite} />}
      >
        <ContentText className="vertical-timeline-element-title">
          {intl.formatMessage({ id: 'timeline.secureMailHeader' })}
          <span className="timeline-date">
            {intl.formatMessage({ id: 'timeline.toThirtyDays' })}
          </span>
        </ContentText>
        <ContentText className="vertical-timeline-element-text">
          {intl.formatMessage({ id: 'timeline.secureMailParagraph' })}
        </ContentText>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--garbagecan"
        iconStyle={{ background: '#79a0d4' }}
        icon={<IconHazardousWasteAndDetoxification color={colors.snowWhite} />}
      >
        <ContentText className="vertical-timeline-element-title">
          {intl.formatMessage({ id: 'timeline.expireHeader' })}
          <span className="timeline-date">
            {intl.formatMessage({ id: 'timeline.sixtyPlusDay' })}
          </span>
        </ContentText>
        <ContentText className="vertical-timeline-element-text">
          {intl.formatMessage({ id: 'timeline.expireParagraph' })}
        </ContentText>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </div>
);

ProgressMap.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ProgressMap);
