/* eslint-disable */

module.exports = {
  logout: 'Logga ut',
  submissionsThisYear: 'Dina informationsförfrågningar i år',
  timeline: {
    requestIsMadeHeader: 'Begäran görs',
    requestIsMadeParagraph:
      'Du kan beställa ett persondatautdrag innehållande din information med hjälp av formuläret på denna sida. Efter mottagandet samlar vi in data från olika system.',
    secureMailHeader: 'Ett krypterat meddelande skickas',
    secureMailParagraph:
      'Du kommer att få ett mail till den e-postadress som du angav med en länk för att kunna ladda ner ditt utdrag.',
    expireHeader: 'Länken löper ut',
    expireParagraph: 'Av säkerhetsskäl kan utdraget endast hämtas under en viss tidsperiod.',
    firstDay: 'dag 0',
    toThirtyDays: '1-30 dagar',
    sixtyPlusDay: '+60 dagar',
  },
  thankYouHeader:
    'Beställningen har skickats',
  thankYou:
    'Fortum svarar på din förfrågan senast inom 30 dagar, till angiven e-postadress.',
  requestFailed:
    'Sending the request failed. Please try again later or contact customer service.',
  basicDataError:
    'Vi hittade inte dig i våra system. Det är möjligt att vi inte har information om dig.',
  headers: {
    electricMessaging: 'Sähköisen viestinnän valinnat',
    requestingOwnInformation: 'Beställ ditt persondatautdrag',
    copyOfYourInfo: 'Beställ persondatautdrag',
  },
  paragraphs: {
    requestingOwnInformation:
      'Ditt förtroende är viktigt för oss och vi på Fortum hanterar din personliga information med största försiktighet. I nedan tjänst så kan du granska och ändra dina uppgifter. Du är alltid välkommen att kontakta vår kundtjänst om du har ytterligare frågor.',
    requestingOwnInformationHR:
      'Ditt förtroende är viktigt för oss och vi på Fortum hanterar din personliga information med största försiktighet. I nedan tjänst så kan du granska och ändra dina uppgifter. Du är alltid välkommen att kontakta vår kundtjänst om du har ytterligare frågor.',
    hereIsYourBasicInfo:
    'Om du har elavtal med Fortum, får du snabbt och enkelt via Mitt Fortum en bra översikt över dina fakturor, din elanvändning och ditt elavtal ',
    copyOfYourInfo:
      'Fyll i formuläret nedan om du vill begära ett utdrag av vilken data Fortum Sverige lagrar kring dig som person. För att kunna skicka rapporten till dig behöver vi en e-postadress.',
    copyOfYourInfoAsCSV:
      'En rapport skickas som PDF',
  },
  labels: {
    name: 'Namn',
    personalID: 'Personnummer',
    email: 'E-post',
  },
  buttons: {
    loginMyFortum: 'Logga in på Mitt Fortum',
    requestAllData: 'Beställ persondatautdrag',
  },
  linkTargets: {
    myFortum: 'https://www.mittfortum.se',
    fortumCom: 'https://www.fortum.se',
    legalNotice: 'https://www.fortum.se/om-oss/juridisk-information',
    privacy: 'https://www.fortum.se/privat/kundservice/gdpr-dataskydd',
  },
  employee: {
    status: 'Anställningsstatus',
    current: 'Tidigare anställd',
    former: 'Nuvarande anställd',
    applicant: 'Arbetssökande',
  },
  footer: {
    fortumCom: 'Fortum.se',
    privacy: 'Integritetspolicy och cookies',
    justice: 'Användarvillkor',
  },
};
