/* eslint-disable */

module.exports = {
  logout: 'Logga ut',
  submissionsThisYear: 'Din informationsförfrågan i år',
  timeline: {
    requestIsMadeHeader: 'Begäran görs',
    requestIsMadeParagraph:
      'Du kan göra en begäran om din egen information med hjälp av formuläret på denna sida. Efter mottagandet av begäran samlar vi in data från olika system.',
    //secureMailHeader: 'Ett säkert meddelande skickas',
    secureMailHeader: 'Det krypterade meddelandet har skickats',
    secureMailParagraph:
      'Inom en månad från det att du skickade in begäran kommer du att motta ett krypterat e-mejl till den e-mejladress du har försett oss med.',
    //expireHeader: 'Länken löper ut',
    expireHeader: 'Öppna ett krypterat mejl',
    
    expireParagraph: 'E-mejlet innehåller instruktioner för hur meddelandet öppnas.',
    firstDay: 'dag 0',
    toThirtyDays: '1-30 dagar',
    //sixtyPlusDay: '+60 dagar',
    sixtyPlusDay: ' ',
  },
  /*expireHeader: 'Öppna ett krypterat mejl',
    expireParagraph: 'E-mejlet innehåller instruktioner för hur meddelandet öppnas.',*/
  thankYouHeader:
    'Din begäran har sänts',
  thankYou:
    'Fortum svarar på din begäran senast inom 30 dagar, till e-postadressen',
  requestFailed:
    'Error occured, please try again.',
  basicDataError:
    'Vi hittade inte din grundläggande information i våra system. Det är möjligt att vi inte har information om dig.',
  headers: {
    electricMessaging: 'Elektroniska kommunikationsval',
    requestingOwnInformation: 'Begär din egen information',
    hereIsYourBasicInfo: 'Här är din grundläggande information',
    energyConsumption: 'Energiförbrukning',
    copyOfYourInfo: 'Kopia av egna uppgifter',
  },
  paragraphs: {
    requestingOwnInformation:
      'Vi behandlar din information noggrant och vi ger dig rätt att behålla, granska eller ta bort dem. Nedan hittar du den grundläggande informationen som vi har sparat om dig. Om du vill få en kopia av all information om dig i vårt system kan du göra en förfrågan med formuläret nedan.',
    requestingOwnInformationHR:
      'Vi behandlar din information noggrant och vi ger dig rätt att behålla, granska eller ta bort dem. Om du vill få en kopia av all information om dig i vårt system kan du göra en förfrågan med formuläret nedan.',
    hereIsYourBasicInfo:
      'Här är din grundläggande information som finns i våra system. Om du för närvarande är vår kund kan du hantera' +
      ' och redigera din information i',
    energyConsumption:
      'Om du för närvarande är kund och vill se konsumtionshistoriken kan du logga in i Mitt Fortum -tjänsten.',
    /*energyConsumption:
      'Om du för närvarande är kund och vill se konsumtionshistoriken kan du logga in i Valpas -tjänsten',*/
    copyOfYourInfo:
      'Om du vill göra en förfrågan angående dina uppgifter, kan du göra det genom att använda det här formuläret. För att ge informationen till dig behöver vi en e-postadress till vilken anmälan om slutförandet av förfrågan skickas. Du kan begära en kopia av alla dina filer eller en begränsad version för att överföra din egen data. Vi kommer att förse dig med information om din data som finns på vårt system. Informationen innehåller bland annat kundinformation, avtals- och produktinformation, information om förbrukningsstället, information om elförbrukning, inköpsinformation från nätbutiken och information om energieffektivitetsprodukter.',
    copyOfYourInfoAsCSV:
      'En kopia av data levereras i .csv-filformat, vilket passar bäst för att öppna ett kalkylbladsprogram. (t.ex. Open Office, Microsoft Excel eller Google docs)',
  },
  labels: {
    name: 'Namn',
    address: 'Adress',
    customerId: 'Kundnummer',
    communicationLanguage: 'Kundkommunikationsspråk',
    phone: 'Telefon',
    accountStatus: 'Status av kundrelation',
    marketingPermissions: 'Elektronisk marknadsföringstillstånd',
    priceNotifications: 'Prismeddelanden',
    personalID: 'Personnummer',
    email: 'E-post',
  },
  missingData: {
    marketingPermissions: 'Ej vald',
    priceNotifications: 'Ej vald',
  },
  customerStatus: {
    active: 'Aktiv',
    inactive: 'Inget aktivt avtal',
  },
  language: {
    finnish: 'På finska',
    swedish: 'På svenska',
    english: 'På engelska',
  },
  channels: {
    mobile: 'Telefon',
    email: 'E-post',
  },
  linkTargets: {
    myFortum: 'https://web.fortum.fi',
    valpas: 'https://web.fortum.fi',
    //valpas: 'https://www.fortum.com/valpas',
    fortumCom: 'https://www.fortum.fi/sv',
    legalNotice: 'https://www.fortum.fi/sv/dataskydd-och-register',
    privacy: 'https://www.fortum.fi/sv/dataskydd-och-register',
  },
  buttons: {
    loginMyFortum: 'Logga in i Oma Fortum',
    openValpas: 'Logga in i Mitt Fortum-tjänsten',
    //openValpas: 'Logga in i Valpas-tjänsten',
    requestAllData: 'Begär information',
  },
  links: {
    myFortum: 'Oma Fortum -tjänsten',
  },
  employee: {
    status: 'Anställningsstatus',
    current: 'Tidigare anställd',
    former: 'Nuvarande anställd',
    applicant: 'Arbetssökande',
  },
  request_type: {
    copy: 'Kopia av min egen data',
    transfer: 'Överföring av egen data',
  },
  footer: {
    fortumCom: 'Fortum.com',
    privacy: 'Privacy Policy och register',
    justice: 'Rättsligt meddelande',
  },
};
