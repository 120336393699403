/* eslint-disable */

module.exports = {
  logout: 'Kirjaudu ulos',
  submissionsThisYear: 'Tietopyyntösi tänä vuonna',
  timeline: {
    requestIsMadeHeader: 'Pyyntö tehdään',
    requestIsMadeParagraph:
      'Voit tehdä pyynnön omista tiedoistasi tällä sivulla olevalla lomakkeella. Pyynnön vastaanottamisen jälkeen keräämme tiedot eri järjestelmistä.',
    secureMailHeader: 'Tietoturvallinen viesti lähetetään',
    secureMailParagraph:
      'Saat antamaasi sähköpostiosoitteeseen salatun sähköpostiviestin kuukauden kuluessa kun pyyntösi on käsitelty.',
    //expireHeader: 'Linkki vanhenee',
    expireHeader: 'Viestin avaaminen',
    expireParagraph: 'Viesti sisältää ohjeen viestin avaamisesta.',
    firstDay: 'Päivä 0',
    toThirtyDays: 'Päivä 1-30',
    //sixtyPlusDay: '+60 Päivää',
    sixtyPlusDay: ' ',
  },
  /*expireHeader: 'Viestin avaaminen',
    expireParagraph: 'Viesti sisältää ohjeen viestin avaamisesta.',*/
  thankYouHeader:
    'Pyyntösi on lähetetty',
  thankYou:
    'Fortum vastaa pyyntöösi viimeistään 30 vuorokauden kuluessa antamaasi sähköpostiosoitteeseen',
  requestFailed:
    'Pyynnön lähetys epäonnistui. Ole hyvä ja yritä myöhemmin uudestaan tai ota yhteys asiakaspalveluun.',
  basicDataError:
    'Emme löytäneet sinun perustietojasi järjestelmistämme. On mahdollista että meillä ei ole tietoa tallennettuna sinusta.',
  headers: {
    electricMessaging: 'Sähköisen viestinnän valinnat',
    requestingOwnInformation: 'Omien tietojen pyytäminen',
    hereIsYourBasicInfo: 'Tässä ovat perustietosi',
    energyConsumption: 'Energian kulutus',
    copyOfYourInfo: 'Kopio omista tiedoista',
  },
  paragraphs: {
    requestingOwnInformation:
      'Käsittelemme tietojasi huolellisesti ja välitämme oikeudestasi ylläpitää, tarkastaa tai poistaa niitä. Alla näet perustiedot, jotka olemme tallentaneet sinusta. Jos haluat saada kopion kaikesta tiedosta, mitä järjestelmistämme sinusta löytyy, voit tehdä pyynnön alla olevalla lomakkeella.',
    requestingOwnInformationHR:
      'Käsittelemme tietojasi huolellisesti ja välitämme oikeudestasi ylläpitää, tarkastaa tai poistaa niitä. Jos haluat saada kopion kaikesta tiedosta, mitä järjestelmistämme sinusta löytyy, voit tehdä pyynnön alla olevalla lomakkeella.',
    hereIsYourBasicInfo:
      'Tässä ovat perustietosi, jotka löytyvät järjestelmistämme. Mikäli olet tällä hetkellä' +
      ' asiakkaamme, voit hallita ja muokata tietojasi ',
    energyConsumption:
      'Mikäli olet tällä hetkellä asiakkaamme ja haluat nähdä kulutushistoriasi, voit kirjautua Oma Fortum -palveluun.',
    /*energyConsumption:
      'Mikäli olet tällä hetkellä asiakkaamme ja haluat nähdä kulutushistoriasi, voit kirjautua' +
      ' Valppaaseen',*/
    copyOfYourInfo:
      'Mikäli haluat tehdä pyynnön koskien tietojasi, voit tehdä sen tällä lomakkeella. Toimittaaksemme tiedot sinulle, tarvitsemme sähköpostiosoitteen, johon ilmoitus pyynnön valmistumisesta toimitetaan. Voit pyytää kopion kaikesta tiedostasi tai rajatumman version omien tietojen siirtämistä varten. Toimitamme sinulle vakiomuotoisen paketin tiedoistasi, joita löytyy järjestelmistämme. Paketti sisältää mm. asiakastietosi, sopimus- ja tuotetietoja, sähkönkäyttöpaikan tietoja, sähkönkäyttöön liittyviä tietoja, verkkokaupan ostotietoja ja energiatehokkuustuotteiden tietoja.',
    copyOfYourInfoAsCSV:
      'Kopio tiedoista toimitetaan .csv tiedostomuodossa, jonka avaamiseen soveltuu parhaiten taulukkolaskentaohjelma. (esim. Open Office, Microsoft Excel tai Google docs)',
  },
  labels: {
    name: 'Nimi',
    address: 'Osoite',
    customerId: 'Asiakasnumero',
    communicationLanguage: 'Asiakasviestinnän kieli',
    phone: 'Puhelin',
    accountStatus: 'Asiakkuuden tila',
    marketingPermissions: 'Sähköisen markkinoinnin lupa',
    priceNotifications: 'Hintailmoitukset',
    personalID: 'Henkilötunnus',
    email: 'Sähköposti',
  },
  missingData: {
    marketingPermissions: 'Ei valittu',
    priceNotifications: 'Ei valittu',
  },
  customerStatus: {
    active: 'Aktiivinen',
    inactive: 'Ei aktiivista sopimusta',
  },
  language: {
    finnish: 'Suomeksi',
    swedish: 'Ruotsiksi',
    english: 'Englanniksi',
  },
  linkTargets: {
    myFortum: 'https://web.fortum.fi',
    valpas: 'https://web.fortum.fi',
    //valpas: 'https://www.fortum.com/valpas',
    fortumCom: 'https://www.fortum.fi',
    legalNotice: 'https://www.fortum.fi/tietosuoja-ja-rekisterit',
    privacy: 'https://www.fortum.fi/tietosuoja-ja-rekisterit',
  },
  channels: {
    mobile: 'Puhelin',
    email: 'Sähköposti',
  },
  buttons: {
    loginMyFortum: 'Kirjaudu Oma Fortumiin',
    openValpas: 'Kirjaudu Oma Fortumiin',
    //openValpas: 'Kirjaudu Valpas-palveluun',
    requestAllData: 'Pyydä tiedot',
  },
  links: {
    myFortum: 'Oma Fortum -palvelussa',
  },
  employee: {
    status: 'Työsuhteen tila',
    current: 'Nykyinen työntekijä',
    former: 'Entinen työntekijä',
    applicant: 'Työnhakija',
  },
  request_type: {
    copy: 'Kopio omista tiedoistani',
    transfer: 'Omien tietojen siirtäminen',
  },
  footer: {
    fortumCom: 'Fortum.com',
    privacy: 'Tietosuoja ja rekisterit',
    justice: 'Oikeudellinen tiedote',
  },
};
