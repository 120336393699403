import React, { useEffect } from 'react';

const Login = () => {
  useEffect(() => {
    const country = window.localStorage.getItem('country');
    const userType = window.localStorage.getItem('userType');
    window.location.href = `/${country}${userType ? (`/${userType}`) : ''}#success`;
    window.location.reload();
    return null;
  }, []);

  return <div style={{ height: '100vh', width: '100%' }} />;
};

export default Login;
