import React from 'react';
import { Loader } from '@fortum/elemental-ui';
import axios from 'axios/index';

class Logout extends React.Component {
  async componentWillMount() {
    let logoutSuccessEndpoint;
    await axios
      .get(`/api/auth/config-info/${window.localStorage.getItem('country')}`)
      .then((res) => {
        const config = res.data;
        logoutSuccessEndpoint = config.logoutSuccess;
      })
      .catch((err) => {
        console.log(err);
      });
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = logoutSuccessEndpoint;
  }

  render() {
    return (
      <div className="wrapper margin-top-large text-center margin-bottom-large">
        <Loader size={256} />
      </div>
    );
  }
}

export default Logout;
