/* eslint-disable */

module.exports = {
  mainHeader: 'Making a request',
  // mainParagraph: 'Sorry, the application form is currently unavailable. We are working to restore its proper functioning. Contact us using the contact form to the {termLink}',
  // mainParagraphlink: 'Privacy Team Contact - Privacy Office PL | Fortum.pl',
  mainParagraph: 'With this form you can make a request for your own data. After filling and submitting the form,' +
  ' you wil be contacted by our customer service within 30 days. Add here some information about making the request' +
  ' and the process',
  formHeader: 'Request form',
  stepOneHeader: 'Personal information',
  stepTwoHeader: 'Connection to Fortum',
  stepThreeHeader: 'Type of request',
  requestFailed: 'Sending the request failed. Please try again later or contact customer service.',
  thankYou:
    'Your request has been sent',
  thankYouParagraph:
    'You will receive a response from Fortum in 30 days.',
  attention: 'NOTE:',
  attentionText: 'The request will be processed after positive authentication. We might need to contact you with' +
  ' regards to this.',
  labels: {
    first_name: 'First name',
    last_name: 'Last name',
    ssn: 'Social security number',
    ssn_code: '11-digit code',
    customer_id: 'Customer number',
    customer_id_code: 'Fortum customer number',
    email: 'Email address',
    contact_number: 'Contact number',
    address_string: 'Street name and house number',
    zip_code: 'Zip code',
    city: 'City',
    are_you_customer: 'I am a current / ex customer',
    current_customer: 'Current customer',
    ex_customer: 'Ex-customer',
    contract_type: 'Contract type',
    electricity: 'Electricity',
    gas: 'Gas',
    heating: 'Heating',
    are_professional: 'I have a professional connection to Fortum',
    company: 'Company',
    professional_current_employee: 'Current Employee',
    professional_ex_employee: 'Ex-employee ',
    professional_potential_employee: 'Potential employee',
    professional_partner_vendor: 'Partner / vendor ',
    professional_other: 'Other, please specify',
    request_type: 'Request type',
    get_my_data: 'Get information about personal data stored by Fortum',
    erase_personal_data: 'Erase personal data from Fortum systems',
    restrict_processing: 'Restrict the processing of personal data in Fortum system(s)',
    object_processing: 'Object the processing of personal data in Fortum system(s)',
    correct_data: 'Correct my personal data in Fortum system(s)',
    transfer_data: 'Transfer my data ',
    stop_marketing_data: 'Stop marketing',
    contact_channel: 'Preferred method of contact',
    contact_email: 'Email',
    contact_phone: 'Phone',
    select_option: 'Select option',
    termsAndConditions: 'I understand that my personal data will be processed in accordance with {termLink} and I declare that I have read its contents.',
    termsAndConditionsLink: 'Fortum’s Privacy Policy'
  },
  errors: {
    required: 'Please fill the required field',
    ssn: 'Please enter a valid SSN',
    email: 'Please enter a valid email address',
  },
  buttons: {
    send: 'Send request',
  },
  linkTargets: {
    fortumCom: 'https://www.fortum.pl',
  },
  footer: {
    fortumCom: 'Fortum.pl',
  },
};
