/* eslint-disable max-len */
import React from 'react';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  Row, Col, Radio, ContentText, RadioButtonGroup, Checkbox,
} from '@fortum/elemental-ui';

class TypeOfRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact_channel: '',
      termsAndConditions: [],
    };

    this.onChange = this.onChange.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
  }

  onChange(e) {
    this.props.formOnChange(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  checkboxChange(e) {
    const currentState = this.state[e.target.name] || [];
    if (includes(currentState, e.target.value)) {
      this.setState({ [e.target.name]: currentState.filter(value => value !== e.target.value) });
    } else {
      this.setState({ [e.target.name]: currentState.concat([e.target.value]) });
    }
  }

  render() {
    return (
      <fieldset>
        <Row className="margin-top">
          <Col xxs={12}>
            {this.props.formErrors && this.props.formErrors.includes('request_type') && (
              <ContentText
                style={{
                  color: '#eb6437',
                }}
              >
                {this.props.intl.formatMessage({ id: 'errors.required' })}
              </ContentText>
            )}
            <RadioButtonGroup
              required
              items={[
                {
                  label: this.props.intl.formatMessage({ id: 'labels.get_my_data' }),
                  value: 'Copy of my data',
                },
                {
                  label: this.props.intl.formatMessage({ id: 'labels.erase_personal_data' }),
                  value: 'Erase my data',
                },
                {
                  label: this.props.intl.formatMessage({ id: 'labels.restrict_processing' }),
                  value: 'Restriction of data',
                },
                {
                  label: this.props.intl.formatMessage({ id: 'labels.object_processing' }),
                  value: 'Stop processing',
                },
                {
                  label: this.props.intl.formatMessage({ id: 'labels.correct_data' }),
                  value: 'Rectify my data',
                },
                {
                  label: this.props.intl.formatMessage({ id: 'labels.transfer_data' }),
                  value: 'Transfer my data',
                },
                {
                  label: this.props.intl.formatMessage({ id: 'labels.stop_marketing_data' }),
                  value: 'Stop marketing',
                },
              ]}
              radioButtonStyle={{ marginBottom: '1rem', display: 'block' }}
              name="request_type"
              label={this.props.intl.formatMessage({ id: 'labels.request_type' })}
              onChange={this.onChange}
            />
          </Col>
        </Row>
        <Row className="margin-top">
          <Col xxs={12}>
            <ContentText
              style={{
                color: '#eb6437',
              }}
            >
              {this.props.intl.formatMessage({ id: 'attention' })}
              <br />
              {this.props.intl.formatMessage({ id: 'attentionText' })}
            </ContentText>
          </Col>
        </Row>
        <Row>
          <Col xxs={12}>
            <div style={{ marginBottom: '1rem' }}>
              <ContentText>
                {this.props.intl.formatMessage({ id: 'labels.contact_channel' })}
              </ContentText>
              {this.props.formErrors && this.props.formErrors.includes('contact_channel') && (
                <ContentText
                  style={{
                    color: '#eb6437',
                  }}
                >
                  {this.props.intl.formatMessage({ id: 'errors.required' })}
                </ContentText>
              )}
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <Radio
                required
                checked={this.state.contact_channel === 'email'}
                label={this.props.intl.formatMessage({ id: 'labels.contact_email' })}
                name="contact_channel"
                onChange={this.onChange}
                value="email"
              />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <Radio
                required
                checked={this.state.contact_channel === 'phone'}
                label={this.props.intl.formatMessage({ id: 'labels.contact_phone' })}
                name="contact_channel"
                onChange={this.onChange}
                value="phone"
              />
            </div>
          </Col>
        </Row>

        <Row className="margin-top margin-bottom">
          <Col xxs={12}>
            {this.props.formErrors && this.props.formErrors.includes('termsAndConditions') && (
              <ContentText
                style={{
                  color: '#eb6437',
                }}
              >
                {this.props.intl.formatMessage({ id: 'errors.required' })}
              </ContentText>
            )}
            <Checkbox
              required
              value="accept"
              checked={this.state.termsAndConditions.includes('accept')}
              label={(
                <FormattedMessage
                  id="labels.termsAndConditions"
                  values={{
                    termLink: (
                      <a href="https://www.fortum.pl/dbamy-o-twoja-prywatnosc" target="_blank" rel="noopener noreferrer">
                        {this.props.intl.formatMessage({ id: 'labels.termsAndConditionsLink' })}
                      </a>
                    ),
                  }}
                />
)}
              name="termsAndConditions"
              onChange={this.checkboxChange}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

TypeOfRequest.propTypes = {
  intl: intlShape.isRequired,
  formOnChange: PropTypes.func,
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(TypeOfRequest);
