import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from '@fortum/elemental-ui';
import { injectIntl, intlShape } from 'react-intl';

const Footer = ({ intl, country }) => (
  <div
    style={{
      background: '#E8E8EB',
      paddingBottom: '0.5em',
    }}
  >
    <footer className="padding-top-small padding-bottom-small text-center cf">
      <Grid>

        {country !== 'pl'
          && (
          <Row>
            <Col m={4} className="margin-top-small">
              <a
                href={intl.formatMessage({ id: 'linkTargets.fortumCom' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="margin-right">{intl.formatMessage({ id: 'footer.fortumCom' })}</span>
              </a>
            </Col>
            <Col m={4} className="margin-top-small">
              <a
                href={intl.formatMessage({ id: 'linkTargets.privacy' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="margin-right">{intl.formatMessage({ id: 'footer.privacy' })}</span>
              </a>
            </Col>
            <Col m={4} className="margin-top-small">
              <a
                href={intl.formatMessage({ id: 'linkTargets.legalNotice' })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{intl.formatMessage({ id: 'footer.justice' })}</span>
              </a>
            </Col>
          </Row>
          )
        }
        {country === 'pl'
        && (
        <Row>
          <Col m={12} className="margin-top-small text-center">
            <a
              href={intl.formatMessage({ id: 'linkTargets.fortumCom' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="margin-right">{intl.formatMessage({ id: 'footer.fortumCom' })}</span>
            </a>
          </Col>
        </Row>
        )
        }
      </Grid>
    </footer>
  </div>
);

Footer.propTypes = {
  intl: intlShape.isRequired,
  country: PropTypes.string,
};

export default injectIntl(Footer);
