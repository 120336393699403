/* eslint-disable max-len */
import React from 'react';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  Row, Col, Radio, Checkbox, ContentText, Select,
} from '@fortum/elemental-ui';

class PersonalInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: false,
      contract_type: [],
      professional: false,
      professional_status: '',
      customership_status: '',
      customer_type: '',
    };

    this.onChange = this.onChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  onChange(e) {
    this.props.formOnChange(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleCheckboxChange(e) {
    const currentState = this.state[e.target.name];
    const onChangeEvent = {
      target: {
        name: e.target.name,
      },
    };

    if (includes(currentState, e.target.value)) {
      const newValue = currentState.filter(value => value !== e.target.value);
      this.setState({ [e.target.name]: newValue });
      onChangeEvent.target.value = newValue;
    } else {
      const newValue = currentState.concat([e.target.value]);
      this.setState({ [e.target.name]: newValue });
      onChangeEvent.target.value = newValue;
    }

    this.props.formOnChange(onChangeEvent);
  }

  render() {
    return (
      <fieldset>
        {this.props.formErrors && this.props.formErrors.includes('customer_type') && (
          <ContentText
            style={{
              color: '#eb6437',
            }}
          >
            {this.props.intl.formatMessage({ id: 'errors.required' })}
          </ContentText>
        )}
        <Row>
          <Col xxs={12} className="margin-bottom-small">
            <Radio
              required
              value="private"
              checked={this.state.customer_type === 'private'}
              label={this.props.intl.formatMessage({ id: 'labels.are_you_customer' })}
              name="customer_type"
              onChange={this.onChange}
            />
          </Col>
        </Row>
        <Row className="margin-left">
          <Col xxs={12} className="margin-bottom-small">
            <Radio
              required={this.state.customer_type === 'private'}
              disabled={this.state.customer_type !== 'private'}
              checked={this.state.customership_status === 'current_customer'}
              label={this.props.intl.formatMessage({ id: 'labels.current_customer' })}
              name="customership_status"
              onChange={this.onChange}
              value="current_customer"
            />
          </Col>
          <Col xxs={12} className="margin-bottom-small">
            <Radio
              required={this.state.customer_type === 'private'}
              disabled={this.state.customer_type !== 'private'}
              checked={this.state.customership_status === 'ex_customer'}
              label={this.props.intl.formatMessage({ id: 'labels.ex_customer' })}
              name="customership_status"
              onChange={this.onChange}
              value="ex_customer"
            />
          </Col>
          <Col xxs={12} className="margin-bottom-small">
            <ContentText className="marginless">{this.props.intl.formatMessage({ id: 'labels.contract_type' })}</ContentText>
          </Col>
          <Col xxs={12} className="margin-bottom-small">
            <Checkbox
              invert
              disabled={this.state.customer_type !== 'private'}
              value="electricity"
              checked={includes(this.state.contract_type, 'electricity')}
              label={this.props.intl.formatMessage({ id: 'labels.electricity' })}
              name="contract_type"
              onChange={this.handleCheckboxChange}
            />
          </Col>
          <Col xxs={12} className="margin-bottom-small">
            <Checkbox
              invert
              disabled={this.state.customer_type !== 'private'}
              value="gas"
              checked={includes(this.state.contract_type, 'gas')}
              label={this.props.intl.formatMessage({ id: 'labels.gas' })}
              name="contract_type"
              onChange={this.handleCheckboxChange}
            />
          </Col>
          <Col xxs={12} className="margin-bottom-small">
            <Checkbox
              invert
              disabled={this.state.customer_type !== 'private'}
              value="heating"
              checked={includes(this.state.contract_type, 'heating')}
              label={this.props.intl.formatMessage({ id: 'labels.heating' })}
              name="contract_type"
              onChange={this.handleCheckboxChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} className="margin-bottom-small">
            <hr className="medium" />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} className="margin-bottom">
            <Radio
              required
              value="b2b"
              checked={this.state.customer_type === 'b2b'}
              label={this.props.intl.formatMessage({ id: 'labels.are_professional' })}
              name="customer_type"
              onChange={this.onChange}
            />
          </Col>
        </Row>
        <Row className="margin-left">
          <Col xxs={12} className="margin-bottom">
            <Select
              required={this.state.customer_type === 'b2b'}
              disabled={this.state.customer_type !== 'b2b'}
              style={{
                maxWidth: '25rem',
              }}
              items={[
                {
                  name: 'Fortum Marketing and Sales Polska S.A.',
                  value: 'Fortum Marketing and Sales Polska S.A.',
                },
                {
                  name: 'Fortum Sprzedaż Sp. z o.o.',
                  value: 'Fortum Sprzedaż Sp. z o.o.',
                },
                {
                  name: 'Fortum Customer Services Polska Sp. z o.o.',
                  value: 'Fortum Customer Services Polska Sp. z o.o.',
                },
                {
                  name: 'Fortum Power and Heat Polska Sp. z o.o.',
                  value: 'Fortum Power and Heat Polska Sp. z o.o.',
                },
                {
                  name: 'Fortum Silesia S.A.',
                  value: 'Fortum Silesia S.A.',
                },
                {
                  name: 'Fortum Network Częstochowa Sp. z o.o.',
                  value: 'Fortum Network Częstochowa Sp. z o.o.',
                },
                {
                  name: 'Fortum Network Płock Sp. z o.o.',
                  value: 'Fortum Network Płock Sp. z o.o.',
                },
                {
                  name: 'Fortum Network Wrocław Sp. z o.o.',
                  value: 'Fortum Network Wrocław Sp. z o.o.',
                },
              ]}
              name="company"
              selected={this.state.company}
              label={this.props.intl.formatMessage({ id: 'labels.company' })}
              placeholder={this.props.intl.formatMessage({ id: 'labels.select_option' })}
              onChange={this.onChange}
            />
          </Col>
          <Col xxs={12} className="margin-bottom">
            <Radio
              required={this.state.customer_type === 'b2b'}
              disabled={this.state.customer_type !== 'b2b'}
              checked={this.state.professional_status === 'Employee'}
              label={this.props.intl.formatMessage({ id: 'labels.professional_current_employee' })}
              name="professional_status"
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
              value="Employee"
            />
          </Col>
          <Col xxs={12} className="margin-bottom">
            <Radio
              required={this.state.customer_type === 'b2b'}
              disabled={this.state.customer_type !== 'b2b'}
              checked={this.state.professional_status === 'Former Employee'}
              label={this.props.intl.formatMessage({ id: 'labels.professional_ex_employee' })}
              name="professional_status"
              onChange={this.onChange}
              value="Former Employee"
            />
          </Col>
          <Col xxs={12} className="margin-bottom">
            <Radio
              required={this.state.customer_type === 'b2b'}
              disabled={this.state.customer_type !== 'b2b'}
              checked={this.state.professional_status === 'Job Applicant'}
              label={this.props.intl.formatMessage({ id: 'labels.professional_potential_employee' })}
              name="professional_status"
              onChange={this.onChange}
              value="Job Applicant"
            />
          </Col>
          <Col xxs={12} className="margin-bottom">
            <Radio
              required={this.state.customer_type === 'b2b'}
              disabled={this.state.customer_type !== 'b2b'}
              checked={this.state.professional_status === 'Consultant'}
              label={this.props.intl.formatMessage({ id: 'labels.professional_partner_vendor' })}
              name="professional_status"
              onChange={this.onChange}
              value="Consultant"
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

PersonalInformation.propTypes = {
  intl: intlShape.isRequired,
  formOnChange: PropTypes.func,
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(PersonalInformation);
