/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { ContentText, Heading, Grid } from '@fortum/elemental-ui';
import ProgressMap from '../components/ProgressMap';
import BasicData from '../components/BasicData';
import RequestAllData from '../components/RequestAllData';

const PageGDPR = ({ match, user, intl }) => (
  <div className="text-center">
    <DocumentTitle title={`${intl.formatMessage({
      id: 'headers.requestingOwnInformation',
    })} – Fortum`}
    />
    <ProgressMap />
    <Grid>
      <div className="text-center margin-top">
        <Heading level={1}>
          <FormattedMessage defaultMessage="Missing" id="headers.requestingOwnInformation" />
        </Heading>
        <ContentText lineHeight="loose">
          {match.params.userType !== 'employee'
            && <FormattedMessage defaultMessage="Missing" id="paragraphs.requestingOwnInformation" />
          }
          {match.params.userType === 'employee'
          && <FormattedMessage defaultMessage="Missing" id="paragraphs.requestingOwnInformationHR" />
          }
        </ContentText>
      </div>
    </Grid>

    {match.params.userType !== 'employee'
      && <hr className="thick" />
    }

    {match.params.userType !== 'employee'
      && (
      <div className="text-center wrapper">
        <BasicData />
      </div>
      )
    }

    <hr className="thick" />

    <RequestAllData user={user} userType={match.params.userType} />
  </div>
);

PageGDPR.propTypes = {
  intl: intlShape,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userType: PropTypes.string,
    }),
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    personalId: PropTypes.string,
  }),
};

export default injectIntl(PageGDPR);
