import 'babel-polyfill';
import 'intl';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import no from 'react-intl/locale-data/no';
import fi from 'react-intl/locale-data/fi';
import sv from 'react-intl/locale-data/sv';
import pl from 'react-intl/locale-data/pl';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import flattenMessages from './utils/flattenMessage';
import getUrlParam from './utils/getUrlParameter';
import FiFiTranslation from './translations/fi/fi';
import FiSvTranslation from './translations/fi/sv';
import FiEnTranslation from './translations/fi/en';
import NoNoTranslation from './translations/no/no';
import SeSvTranslation from './translations/se/sv';
import PlEnTranslation from './translations/pl/en';
import PlPlTranslation from './translations/pl/pl';

addLocaleData([...fi, ...no, ...sv, ...pl]);

const messages = {
  fi: {
    fi: FiFiTranslation,
    sv: FiSvTranslation,
    en: FiEnTranslation,
  },
  no: {
    no: NoNoTranslation,
  },
  se: {
    sv: SeSvTranslation,
  },
  pl: {
    pl: PlPlTranslation,
    en: PlEnTranslation,
  },
};

const target = document.querySelector('#root');

const country = window.location.pathname.split('/')[1];
const userType = window.location.pathname.split('/')[2];

let language;

let defaultLang;

switch (country.toUpperCase()) {
  case 'FI':
    defaultLang = 'fi';
    break;
  case 'SE':
    defaultLang = 'sv';
    break;
  case 'NO':
    defaultLang = 'no';
    break;
  default:
    defaultLang = 'en';
}

if (getUrlParam('lang') && getUrlParam('lang').length > 1) {
  language = getUrlParam('lang');
} else if (
  window.localStorage.getItem('language')
  && window.localStorage.getItem('language').length > 1
) {
  language = window.localStorage.getItem('language');
} else {
  language = defaultLang;
}

if (typeof messages[country] === 'undefined') {
  language = 'en';
} else if (typeof messages[country][language] === 'undefined') {
  language = defaultLang;
}
if (['fi', 'se', 'no', 'pl'].includes(country)) {
  window.localStorage.setItem('country', country);
  window.localStorage.setItem('language', language);
}
if (userType && userType === 'employee') {
  window.localStorage.setItem('userType', userType);
}
let countryMessages;

if (typeof messages[country] === 'undefined') {
  countryMessages = messages.fi;
} else {
  countryMessages = messages[country];
}

const LOCAL_MESSAGES = countryMessages[language] || messages.fi.en;

ReactDOM.render(
  <IntlProvider locale={language} messages={flattenMessages(LOCAL_MESSAGES)}>
    <BrowserRouter>
      <div className={country !== 'pl' ? 'gdprsite-other' : 'gdpr-poland'}>
        <App country={country} />
      </div>
    </BrowserRouter>
  </IntlProvider>,
  target,
);
