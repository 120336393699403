/* eslint-disable max-len */
import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Stepper, Heading, ContentText } from '@fortum/elemental-ui';
import axios from 'axios';
import PersonalInformation from './PersonalInformation';
import ConnectionToFortum from './ConnectionToFortum';
import TypeOfRequest from './TypeOfRequest';
import SendTheForm from './SendTheForm';

class RequestAllData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        country: window.localStorage.getItem('country'),
        customer_language: this.props.intl.locale,
      },
      formErrors: [],
      loading: false,
      submitError: false,
      sent: false,
    };

    this.formOnChange = this.formOnChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  formOnChange(e) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  submitForm(e) {
    e.preventDefault();
    const inputs = document.getElementById('gdpr-form').elements;
    const errors = [];
    for (let i = 0; i < inputs.length; i += 1) {
      if (!inputs[i].validity.valid) {
        errors.push(inputs[i].name);
      }
    }

    this.setState({
      formErrors: errors,
    });

    if (errors.length === 0) {
      this.setState({ loading: true });
      axios
        .post(
          '/api/request/submit-form',
          this.state.formData,
        )
        .then(() => this.setState({ sent: true, loading: false, submitError: false }))
        .catch(() => this.setState({ sent: false, loading: false, submitError: true }));
    } else {
      document.getElementsByName(errors[0])[0].focus();
      window.setTimeout(() => {
        document.getElementsByName(errors[0])[0].blur();
      }, 100);
    }
  }

  render() {
    const steps = [
      {
        id: 'personal-information',
        header: this.props.intl.formatMessage({ id: 'stepOneHeader' }),
        content: <PersonalInformation errors={this.state.formErrors} formOnChange={this.formOnChange} />,
      },
      {
        id: 'connection-to-fortum',
        header: this.props.intl.formatMessage({ id: 'stepTwoHeader' }),
        content: <ConnectionToFortum formErrors={this.state.formErrors} formOnChange={this.formOnChange} />,
      },
      {
        id: 'type-of-request',
        header: this.props.intl.formatMessage({ id: 'stepThreeHeader' }),
        content: <TypeOfRequest formErrors={this.state.formErrors} formOnChange={this.formOnChange} />,
      },
      {
        id: 'submit',
        header: '',
        content: <SendTheForm formErrors={this.state.formErrors} loading={this.state.loading} submitError={this.state.submitError} />,
      },
    ];

    return (
      <form type="submit" onSubmit={this.submitForm} id="gdpr-form" noValidate>
        {!this.state.sent
          && (
          <div className="margin-bottom-large">
            <Heading level={2} className="text-center">
              <FormattedMessage defaultMessage="Missing" id="formHeader" />
            </Heading>
            <Stepper
              steps={steps}
            />
          </div>
          )
        }
        {this.state.sent
        && (
        <div className="text-center">
          <Heading level={2} className="text-center">
            <FormattedMessage defaultMessage="Missing" id="thankYou" />
          </Heading>
          <ContentText className="text-center margin-bottom">
            <FormattedMessage defaultMessage="Missing" id="thankYouParagraph" />
          </ContentText>
        </div>
        )
        }
      </form>
    );
  }
}

RequestAllData.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(RequestAllData);
