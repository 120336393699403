/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  Row, Col, InputField, Radio,
} from '@fortum/elemental-ui';

class PersonalInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authentication_number: 'ssn',
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.formOnChange(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const langPL = this.props.intl.locale === 'pl';
    return (
      <fieldset>
        <Row>
          <Col xxs={12} m={6} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('first_name') > -1}
              autoComplete="given-name"
              onChange={this.onChange}
              value={this.state.first_name}
              className="full-width"
              name="first_name"
              label={this.props.intl.formatMessage({ id: 'labels.first_name' })}
            />
          </Col>
          <Col xxs={12} m={6} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('last_name') > -1}
              autoComplete="family-name"
              onChange={this.onChange}
              value={this.state.last_name}
              className="full-width"
              name="last_name"
              label={this.props.intl.formatMessage({ id: 'labels.last_name' })}
            />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} s={langPL ? 4 : 6} l={langPL ? 3 : 4} className="margin-top">
            <Radio
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('authentication_number') > -1}
              style={{
                marginTop: '1rem',
              }}
              checked={this.state.authentication_number === 'ssn'}
              label={this.props.intl.formatMessage({ id: 'labels.ssn' })}
              name="authentication_number"
              onChange={this.onChange}
              value="ssn"
            />
          </Col>
          <Col xxs={12} s={langPL ? 8 : 6} l={langPL ? 9 : 8} className="margin-top">
            <InputField
              missed={this.props.errors.indexOf('ssn') > -1 && this.state.authentication_number === 'ssn'}
              disabled={this.state.authentication_number !== 'ssn'}
              required={this.state.authentication_number === 'ssn'}
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              onChange={this.onChange}
              value={this.state.authentication_number === 'ssn' ? this.state.ssn : ''}
              className="full-width"
              validator="^[0-9]{11,11}$"
              validatorText={this.props.intl.formatMessage({ id: 'errors.ssn' })}
              name="ssn"
              label={this.props.intl.formatMessage({ id: 'labels.ssn_code' })}
            />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} s={langPL ? 4 : 6} l={langPL ? 3 : 4} className="margin-top">
            <Radio
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('authentication_number') > -1}
              style={{
                marginTop: '1rem',
              }}
              checked={this.state.authentication_number === 'customer_id'}
              label={this.props.intl.formatMessage({ id: 'labels.customer_id' })}
              name="authentication_number"
              onChange={this.onChange}
              value="customer_id"
            />
          </Col>
          <Col xxs={12} s={langPL ? 8 : 6} l={langPL ? 9 : 8} className="margin-top">
            <InputField
              missed={this.props.errors.indexOf('customer_id') > -1 && this.state.authentication_number === 'customer_id'}
              disabled={this.state.authentication_number !== 'customer_id'}
              required={this.state.authentication_number === 'customer_id'}
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              onChange={this.onChange}
              value={this.state.authentication_number === 'customer_id' ? this.state.customer_id : ''}
              className="full-width"
              name="customer_id"
              label={this.props.intl.formatMessage({ id: 'labels.customer_id_code' })}
            />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} m={6} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('email') > -1}
              autoComplete="email"
              onChange={this.onChange}
              value={this.state.email}
              className="full-width"
              name="email"
              type="email"
              validator="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              validatorText={this.props.intl.formatMessage({ id: 'errors.email' })}
              label={this.props.intl.formatMessage({ id: 'labels.email' })}
            />
          </Col>
          <Col xxs={12} m={6} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('contact_number') > -1}
              autoComplete="tel-national"
              onChange={this.onChange}
              value={this.state.contact_number}
              className="full-width"
              name="contact_number"
              label={this.props.intl.formatMessage({ id: 'labels.contact_number' })}
            />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('address_string') > -1}
              autoComplete="address-line1"
              onChange={this.onChange}
              value={this.state.address_string}
              className="full-width"
              name="address_string"
              label={this.props.intl.formatMessage({ id: 'labels.address_string' })}
            />
          </Col>
        </Row>
        <Row>
          <Col xxs={12} m={6} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('zip_code') > -1}
              autoComplete="postal-code"
              onChange={this.onChange}
              value={this.state.zip_code}
              className="full-width"
              name="zip_code"
              label={this.props.intl.formatMessage({ id: 'labels.zip_code' })}
            />
          </Col>
          <Col xxs={12} m={6} className="margin-top">
            <InputField
              required
              requiredText={this.props.intl.formatMessage({ id: 'errors.required' })}
              missed={this.props.errors.indexOf('city') > -1}
              autoComplete="address-level2"
              onChange={this.onChange}
              value={this.state.city}
              className="full-width"
              name="city"
              label={this.props.intl.formatMessage({ id: 'labels.city' })}
            />
          </Col>
        </Row>
      </fieldset>
    );
  }
}

PersonalInformation.propTypes = {
  intl: intlShape.isRequired,
  formOnChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
};

PersonalInformation.defaultProps = {
  errors: [],
};

export default injectIntl(PersonalInformation);
